import React, { FC, useEffect, useState } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import Authentication from '../oauth/Authentication'

interface PrivateRouteProps extends RouteProps {
  roles?: string[]
}

const PrivateRoute: FC<PrivateRouteProps> = ({ roles, ...rest }) => {
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false)
  const userRole = localStorage.getItem('role') ?? ''
  const [isAuthorized, setAuthorized] = useState<boolean>(!roles || roles.includes(userRole))

  useEffect(() => {
    if (!isAuthenticated) {
      Authentication.isLogged().then(isAuthenticated => {
        if (!isAuthenticated) {
          if(!Authentication.authorize(false)) {
            window.location.replace(`/logout`)
          }
        } else {
          setAuthenticated(true)
        }
      })
    }
  }, [roles, userRole])

  if (!isAuthorized) {
    return <Redirect to="/tableau-de-bord" />
  }

  return (
    <Route {...rest} />
  )
}

export default PrivateRoute
